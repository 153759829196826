'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _createClass = function () {
    function defineProperties(target, props) {
        for (var i = 0; i < props.length; i++) {
            var descriptor = props[i];
            descriptor.enumerable = descriptor.enumerable || false;
            descriptor.configurable = true;
            if ('value' in descriptor)
                descriptor.writable = true;
            Object.defineProperty(target, descriptor.key, descriptor);
        }
    }
    return function (Constructor, protoProps, staticProps) {
        if (protoProps)
            defineProperties(Constructor.prototype, protoProps);
        if (staticProps)
            defineProperties(Constructor, staticProps);
        return Constructor;
    };
}();
var _icons = require('./icons');
var _icons2 = _interopRequireDefault(_icons);
var _player = require('../template/player.art');
var _player2 = _interopRequireDefault(_player);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
var Template = function () {
    function Template(options) {
        _classCallCheck(this, Template);
        this.container = options.container;
        this.options = options.options;
        this.randomOrder = options.randomOrder;
        this.init();
    }
    _createClass(Template, [{
            key: 'init',
            value: function init() {
                var cover = '';
                if (this.options.audio.length) {
                    if (this.options.order === 'random') {
                        cover = this.options.audio[this.randomOrder[0]].cover;
                    } else {
                        cover = this.options.audio[0].cover;
                    }
                }
                this.container.innerHTML = (0, _player2.default)({
                    options: this.options,
                    icons: _icons2.default,
                    cover: cover,
                    getObject: function getObject(obj) {
                        return obj;
                    }
                });
                this.lrc = this.container.querySelector('.aplayer-lrc-contents');
                this.lrcWrap = this.container.querySelector('.aplayer-lrc');
                this.ptime = this.container.querySelector('.aplayer-ptime');
                this.info = this.container.querySelector('.aplayer-info');
                this.time = this.container.querySelector('.aplayer-time');
                this.barWrap = this.container.querySelector('.aplayer-bar-wrap');
                this.button = this.container.querySelector('.aplayer-button');
                this.body = this.container.querySelector('.aplayer-body');
                this.list = this.container.querySelector('.aplayer-list');
                this.listOl = this.container.querySelector('.aplayer-list ol');
                this.listCurs = this.container.querySelectorAll('.aplayer-list-cur');
                this.played = this.container.querySelector('.aplayer-played');
                this.loaded = this.container.querySelector('.aplayer-loaded');
                this.thumb = this.container.querySelector('.aplayer-thumb');
                this.volume = this.container.querySelector('.aplayer-volume');
                this.volumeBar = this.container.querySelector('.aplayer-volume-bar');
                this.volumeButton = this.container.querySelector('.aplayer-time button');
                this.volumeBarWrap = this.container.querySelector('.aplayer-volume-bar-wrap');
                this.loop = this.container.querySelector('.aplayer-icon-loop');
                this.order = this.container.querySelector('.aplayer-icon-order');
                this.menu = this.container.querySelector('.aplayer-icon-menu');
                this.pic = this.container.querySelector('.aplayer-pic');
                this.title = this.container.querySelector('.aplayer-title');
                this.author = this.container.querySelector('.aplayer-author');
                this.dtime = this.container.querySelector('.aplayer-dtime');
                this.notice = this.container.querySelector('.aplayer-notice');
                this.miniSwitcher = this.container.querySelector('.aplayer-miniswitcher');
                this.skipBackButton = this.container.querySelector('.aplayer-icon-back');
                this.skipForwardButton = this.container.querySelector('.aplayer-icon-forward');
                this.skipPlayButton = this.container.querySelector('.aplayer-icon-play');
                this.lrcButton = this.container.querySelector('.aplayer-icon-lrc');
            }
        }]);
    return Template;
}();
exports.default = Template;