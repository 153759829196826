'use strict';
var _typeof = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol' ? function (obj) {
    return typeof obj;
} : function (obj) {
    return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
};
var g;
g = function () {
    return this;
}();
try {
    g = g || Function('return this')() || (1, eval)('this');
} catch (e) {
    if ((typeof window === 'undefined' ? 'undefined' : _typeof(window)) === 'object')
        g = window;
}
module.exports = g;