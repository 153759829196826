'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var _play = require('../assets/play.svg');
var _play2 = _interopRequireDefault(_play);
var _pause = require('../assets/pause.svg');
var _pause2 = _interopRequireDefault(_pause);
var _volumeUp = require('../assets/volume-up.svg');
var _volumeUp2 = _interopRequireDefault(_volumeUp);
var _volumeDown = require('../assets/volume-down.svg');
var _volumeDown2 = _interopRequireDefault(_volumeDown);
var _volumeOff = require('../assets/volume-off.svg');
var _volumeOff2 = _interopRequireDefault(_volumeOff);
var _orderRandom = require('../assets/order-random.svg');
var _orderRandom2 = _interopRequireDefault(_orderRandom);
var _orderList = require('../assets/order-list.svg');
var _orderList2 = _interopRequireDefault(_orderList);
var _menu = require('../assets/menu.svg');
var _menu2 = _interopRequireDefault(_menu);
var _loopAll = require('../assets/loop-all.svg');
var _loopAll2 = _interopRequireDefault(_loopAll);
var _loopOne = require('../assets/loop-one.svg');
var _loopOne2 = _interopRequireDefault(_loopOne);
var _loopNone = require('../assets/loop-none.svg');
var _loopNone2 = _interopRequireDefault(_loopNone);
var _loading = require('../assets/loading.svg');
var _loading2 = _interopRequireDefault(_loading);
var _right = require('../assets/right.svg');
var _right2 = _interopRequireDefault(_right);
var _skip = require('../assets/skip.svg');
var _skip2 = _interopRequireDefault(_skip);
var _lrc = require('../assets/lrc.svg');
var _lrc2 = _interopRequireDefault(_lrc);
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}
var Icons = {
    play: _play2.default,
    pause: _pause2.default,
    volumeUp: _volumeUp2.default,
    volumeDown: _volumeDown2.default,
    volumeOff: _volumeOff2.default,
    orderRandom: _orderRandom2.default,
    orderList: _orderList2.default,
    menu: _menu2.default,
    loopAll: _loopAll2.default,
    loopOne: _loopOne2.default,
    loopNone: _loopNone2.default,
    loading: _loading2.default,
    right: _right2.default,
    skip: _skip2.default,
    lrc: _lrc2.default
};
exports.default = Icons;